import React, { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment-timezone";
import "moment-timezone";
import TagManager from 'react-gtm-module';
import DatePicker, { registerLocale } from "react-datepicker";
import { Box, Button, Divider, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import StarIcon from "@material-ui/icons/Star";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import LayoutContainer from "../layout/LayoutContainer";
import LayoutItem from "../layout/LayoutItem";
import NumbersBoxes from "./../shared/NumbersBoxes";
import Copyright from "./../shared/Copyright";
import CompactCarouselDate from "./CompactCarouselDate";
import AvailableTime from "./AvailableTime";
import Section from './Section';
import EventCarousel from './../event/EventCarousel';
import { incrementLoader, decrementLoader, updateProperty, updateStateVariable, timerUpdate, selectedEvent } from "./../../features/store/storeSlice";
import { getShiftById, getShifts } from "./../../features/store/api";
import { checkCapacity, checkWidgetIntervalCapacity } from "./../../features/store/api";
import AvailableEvent from "./AvailableEvent";
import "./../../assets/scss/Reservation.scss";
import "react-datepicker/dist/react-datepicker.css";
import ru from "date-fns/locale/ru";
import en from "date-fns/locale/en-US";
import ar from "date-fns/locale/ar-SA";
import de from "date-fns/locale/de";
import es from "date-fns/locale/es";
import pt from "date-fns/locale/pt";
import SmallLoader from "../shared/SmallLoader";
import DurationOfPackage from "./DurationOfPackage";
registerLocale("ru", ru);
registerLocale("en", en);
registerLocale("ar", ar);
registerLocale("de", de);
registerLocale("es", es);
registerLocale("pt", pt);

export default function Create(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const { allowedDays, defaultLang, highlightedEvents, previewMode, preferredSections, preferredTables, reservation, sections, theme, timerToggle, venueDetails, selectedEvent, shiftsAvailableTimes, allShiftsAvailableTimes, allAllowedDays } = useSelector((state) => state.store);
  const [selectedDate, setSelectedDate] = useState("");
  const [capacityErrors, setCapacityErrors] = useState("");
  const [allShiftsDisabled, setAllShiftsDisabled] = useState(false);
  const [timeErrorMessage, setTimeErrorMessage] = useState(false);
  const [selectedMaxCovers, setSelectedMaxCovers] = useState(reservation.section.max_guest_count);
  const [isEventSelected, setIsEventSelected] = useState(false);
  const [selectEventError, setSelectEventError] = useState(false);
  const [hideNextButton, setHideNextButton] = useState(false);
  const [availableTimeLoader, setAvailableTimeLoader] = useState(false);
  const [guestCountLoader, setGuestCountLoader] = useState(false);
  const [availableEventsLoader, setAvailableEventsLoader] = useState(false);
  const [durations, setDurations] = useState([]);

  const query = new URLSearchParams(history.location.search);
  const queryParams = new URLSearchParams(window.location.search);

  const useStyles = makeStyles((them) => ({
    dateCont: {
      "& .react-datepicker__day": {
        color: theme.calendarTextColor
          ? theme.calendarTextColor + " !important"
          : "",
        fontFamily: theme.calendarFontFamily ? theme.calendarFontFamily : "",
      },
      "& .react-datepicker__day--selected": {
        backgroundColor: theme.calendarSelectedAreaColor
          ? theme.calendarSelectedAreaColor + " !important"
          : "",
        color: theme.calendarSelectedColor
          ? theme.calendarSelectedColor + " !important"
          : "",
        height: "33px",
        width: "33px",
        padding: "0px",
        lineHeight: "33px",
      },
      "& .react-datepicker__day:hover": {
        backgroundColor: theme.calendarSelectedAreaColor
          ? theme.calendarSelectedAreaColor + " !important"
          : "",
        color: theme.calendarSelectedColor
          ? theme.calendarSelectedColor + " !important"
          : "",
      },
      "& .react-datepicker__day--disabled": {
        color: "#e2e2e2 !important",
        pointerEvents: "none"
      },
      "& .react-datepicker__current-month": {
        color: theme.calendarTextColor
          ? theme.calendarTextColor + " !important"
          : "",
      },
    }
  }));

  const classes = useStyles();

  const buttonDesign = {
    backgroundColor: theme.buttonBackgroundColor
      ? theme.buttonBackgroundColor
      : "",
    color: theme.buttonTextColor ? theme.buttonTextColor : "",
    fontFamily: theme.calendarFontFamily ? theme.calendarFontFamily : "",
  };

  const colorDesign = {
    color: theme.calendarTextColor ? theme.calendarTextColor : "",
    fontFamily: theme.calendarFontFamily ? theme.calendarFontFamily : "",
  };

  const defaultBoxColor = {
    backgroundColor: theme.calendarBoxColor ? theme.calendarBoxColor : "",
    opacity: theme.defaultBoxOpacity ? theme.defaultBoxOpacity : "",
    color: theme.calendarLinkColor ? theme.calendarLinkColor : "",
    fontFamily: theme.calendarFontFamily ? theme.calendarFontFamily : "",
  };

  const selectedStyle = {
    fontFamily: theme.calendarFontFamily ? theme.calendarFontFamily : "",
    color: theme.calendarSelectedColor ? theme.calendarSelectedColor : "",
    backgroundColor: theme.calendarSelectedAreaColor
      ? theme.calendarSelectedAreaColor
      : "",
    opacity: theme.selectedColorOpacity ? theme.selectedColorOpacity : "",
  };

  const widgetBackgroundColor = {
    backgroundColor: theme.widgetBackgroundColor
      ? theme.widgetBackgroundColor
      : '',
    borderRadius: theme.widgetBorderRadius
      ? parseInt(theme.widgetBorderRadius)
      : ''
  };

  const [nextButtonClicked, setNextButtonClicked] = useState(false);
  const dispatch = useDispatch();

  const reBuildSections = (activeSections, guestCount) => {
    const sections = [];
    if (activeSections && activeSections.length) {
      activeSections.forEach((activeSection) => {

        let filterSection = preferredSections.filter((section) => {
          return section.id == activeSection.id && !section.is_blocked;
        });

        if (filterSection.length > 0) {
          sections.push({ id: filterSection[0].id, name: filterSection[0].name, is_blocked: !(guestCount >= filterSection[0].min_capacity) || !(guestCount <= filterSection[0].max_capacity), max_capacity: filterSection[0].max_capacity, min_capacity: filterSection[0].min_capacity });
        }
      });
    }

    dispatch(
      updateStateVariable({
        key: "preferredTables",
        value: sections
      })
    );
    return sections;
  }
  function timeDateInBetween(time, start, end, format, rule) {
    let moment_time = moment(time, format),
      moment_start = moment(start, format),
      moment_end = moment(end, format);
    return moment_time.isBetween(moment_start, moment_end, null, rule);
  };

  function checkDurationPerOfSection(sec) {
    if (sec.event_id) {
      return sec.settings.show_duration_for_packages && sec.settings.show_duration_for_packages != 0 && sec.payment_group && !sec.payment_group.hide_online_packages_categories && sec.payment_group.is_time_included && sec.payment_group.payment_type === 'direct' && sec.payment_group.packages && sec.payment_group.packages.length
    }
    return sec.show_duration_for_packages && sec.show_duration_for_packages != 0 && sec.payment_group && !sec.payment_group.hide_online_packages_categories && sec.payment_group.is_time_included && sec.payment_group.payment_type === 'direct' && sec.payment_group.packages && sec.payment_group.packages.length
  }

  function selectSection(sect, startTime, capacity) {
    dispatch(updateProperty({ key: "preferredTable", value: 'Any section' }));
    dispatch(updateProperty({ key: "sectionId", value: 0 }));
    let section = JSON.parse(JSON.stringify(sect));

    if (props.mode && props.mode !== 'event' && sect.event_id) {
      section = JSON.parse(JSON.stringify(sections.find(sec => {
        return sec.shift_id == sect.shift_id
      })));
    }

    setTimeErrorMessage(false);
    section.selectedTime = { start_time: startTime, capacity: capacity }
    dispatch(updateProperty({ key: "duration", value: '' }));
    // section.allow_guests_to_select_section = true;
    let shiftEvents = [];
    if (props.mode && props.mode !== 'event' && !section.event_id) {
      section.events.forEach(ev => {
        if (ev.online_active_intervals && ev.online_active_intervals.length) {
          let timeAvailable = ev.online_active_intervals.filter(onlineCapacity => {
            return startTime == onlineCapacity.start_time;
          });
          if (timeAvailable.length) {
            if (checkDurationPerOfSection(ev)) {
              ev.payment_group.packages.forEach(packageItem => {
                if (packageItem.duration === 0) {
                  packageItem.duration = parseInt(ev.settings.block_hour);
                }
              });
            }
            shiftEvents.push(ev);
          }
        }
      });
      let shiftAsEvent = { event_name: 'normal booking for ' + section.shift_name, id: 'shift' };
      if (!section.guests_must_book_for_an_event_only) {
        shiftEvents.push(shiftAsEvent)
        if (!sect.event_id) dispatch(updateStateVariable({ key: "selectedEvent", value: shiftAsEvent }));
      }

      section.events = shiftEvents;

      if (checkDurationPerOfSection(section)) {
        section.payment_group.packages.forEach(packageItem => {
          if (packageItem.duration === 0) {
            packageItem.duration = parseInt(section.block_hour);
          }
        });
      }

      dispatch(updateProperty({ key: "section", value: section }));
    }

    dispatch(updateProperty({ key: "time", value: { time: startTime } }));

    let allowAnySection = section.allow_guests_to_select_section && section.allow_any_section == 1;

    if (section.min_guest_count > 2) {
      let preferredTabs = reBuildSections(section.online_active_sections, section.min_guest_count);

      if (allowAnySection) {
        dispatch(updateProperty({ key: "preferredTable", value: 'Any section' }));
        dispatch(updateProperty({ key: "sectionId", value: 0 }));
      } else {
        if (preferredTabs && preferredTabs.length) {
          dispatch(updateProperty({ key: "preferredTable", value: preferredTabs[0]['name'] }));
          dispatch(updateProperty({ key: "section_id", value: preferredTabs[0]['id'] }));
        }
      }

      dispatch(updateProperty({ key: "tableFor", value: section.min_guest_count }));
      handleWidgetIntervalCapacity(reservation.date, section.shift_id, section.min_guest_count, preferredTabs.length > 0 && !allowAnySection ? preferredTabs[0]['id'] : 0, sect.event_id ? sect.event_id : '', reservation.duration ? reservation.duration : 0, startTime);
    } else {
      let preferredTabs = reBuildSections(section.online_active_sections, 2);

      if (allowAnySection) {
        dispatch(updateProperty({ key: "preferredTable", value: 'Any section' }));
        dispatch(updateProperty({ key: "sectionId", value: 0 }));
      } else {
        if (preferredTabs && preferredTabs.length) {
          dispatch(updateProperty({ key: "preferredTable", value: preferredTabs[0]['name'] }));
          dispatch(updateProperty({ key: "section_id", value: preferredTabs[0]['id'] }));
        }
      }

      dispatch(updateProperty({ key: "tableFor", value: 2 }));
      handleWidgetIntervalCapacity(reservation.date, section.shift_id, 2, preferredTabs.length > 0 && !allowAnySection ? preferredTabs[0]['id'] : 0, sect.event_id ? sect.event_id : '', reservation.duration ? reservation.duration : 0, startTime);
    }
    let result = section.max_guest_count < capacity ? section.max_guest_count : capacity
    setSelectedMaxCovers(result);

    buildDurations(section);

    if (props.onChooseSection) {
      props.onChooseSection(section.name);
    }
  }

  function onChooseNumber(value) {
    let tempError = 'There are no seats available for your party size. Please call the restaurant for availability or select another day';
    // tempError = capacityErrors
    dispatch(updateProperty({ key: "tableFor", value: value || 2 }));
    if (!reservation.section.allow_waitlist) {
      checkDisabledShifts(value, sections);
      if (checkDisabledValue(reservation.section, value))
        setTimeErrorMessage(true);
      else setTimeErrorMessage(false);
    }

    let res = allShiftsAvailableTimes.find(item => {
      return item.start_time == reservation.time.time
    })
    if (res && res.capacity < value) {
      setCapacityErrors(tempError);
      setHideNextButton(true);
    } else {
      setCapacityErrors('');
      setHideNextButton(false);
    }
    reBuildSections(reservation.section.online_active_sections, value || 2);
  }

  function onChangeDate(value) {
    setCapacityErrors('');
    setTimeErrorMessage(false);
    setAllShiftsDisabled(false);
    let date = moment(value).locale('en').format("YYYY-MM-DD");
    setSelectedDate(date);
    dispatch(
      updateProperty({
        key: "selectedEvent",
        value: {
          section_name: "",
        },
      })
    );
    dispatch(updateProperty({ key: "time", value: { time: "" } }));
    dispatch(updateProperty({ key: "date", value: date }));
    dispatch(updateProperty({ key: "tableFor", value: 0 }));
    if (!timerToggle) dispatch(timerUpdate());

    reBuildSections(reservation.section.online_active_sections, reservation.tableFor);
  }

  function checkDisabledShifts(tableFor, sectss) {
    let counter = 0;

    let sects = JSON.parse(JSON.stringify(sectss));
    sects.forEach((shift, index) => {
      if (checkDisabledValue(shift, tableFor)) {
        shift.disabled = true;

        counter++;
      } else {
        shift.disabled = false;
      }
    });

    dispatch(
      updateStateVariable({
        key: "sections",

        value: sects,
      })
    );

    if (counter === sects.length) {
      setAllShiftsDisabled(true);
    } else {
      setAllShiftsDisabled(false);
    }

    return counter === sects.length;
  }

  function checkDisabledValue(value, tableFor) {
    let disabled = false;

    if (reservation.section.shift_id) {
      let numberOfSeats = tableFor ? tableFor : reservation.tableFor;

      let resu = value.available_time.filter((time) => {
        return ((time.capacity < numberOfSeats || time.capacity === 0) && !reservation.section.allow_waitlist);
      });

      if (value.available_time.length === resu.length) {
        disabled = true;
      }
    }

    return disabled;
  }

  useEffect(() => {

    let eventId = 0;
    if (props.mode === 'event') {
      eventId = selectedEvent.event_id;
    }
    if (selectedDate && !previewMode) {
      setGuestCountLoader(true);
      setAvailableTimeLoader(true);
      setAvailableEventsLoader(true);
      props.getDataFunction(selectedDate, eventId, false).then((res) => {
        setAvailableTimeLoader(false);
        setGuestCountLoader(false);
        setAvailableEventsLoader(false);
        // checkDisabledShifts(reservation.tableFor ? reservation.tableFor : 0, res);
      });
    }
  }, [selectedDate]);

  useEffect(() => {
    if (sections && sections.length > 0) {
      let obj = {};
      let filterDurations = [];
      obj = JSON.parse(JSON.stringify(selectedEvent));
      obj?.payment_group?.packages?.forEach(packageItem => {
        if (packageItem.duration === 0) {
          packageItem.duration = parseInt(obj.settings.block_hour);
        }
        if (!filterDurations.includes(packageItem.duration)) { filterDurations.push(packageItem.duration); }
      });
      if (filterDurations.length) {
        setDurations(filterDurations.sort((a, b) => a > b ? 1 : -1));
        dispatch(updateProperty({ key: "duration", value: reservation.duration }));
        // handleWidgetIntervalCapacity(reservation.date, reservation.section.shift_id, reservation.tableFor, 0, 0, reservation.duration ? reservation.duration : filterDurations[0]);
      }
    }
    dispatch(
      updateProperty({ key: "selectedCategories", value: [] })
    );
    dispatch(
      updateProperty({ key: "totalAmount", value: 0 })
    );
    dispatch(
      updateStateVariable({ key: "allowedDays", value: allAllowedDays })
    );
    let path = window.location.pathname;
    if (reservation.section.event_id && !path.includes('/event')) {
      props.getDataFunction(reservation.date, '');
      dispatch(
        updateProperty({
          key: "time",
          value: {
            time: "",
          },
        })
      );
    }
    if (reservation.time.time && reservation.section.shift_id && !selectedEvent.event_id) {
      handleWidgetIntervalCapacity(reservation.date, reservation.section.shift_id ? reservation.section.shift_id : reservation.section.event_id, reservation.section.min_guest_count, preferredTables.length === 1 ? preferredTables[0]['id'] : 0, reservation.section.event_id ? reservation.section.event_id : '', reservation.duration ? reservation.duration : 0);

      buildDurations(reservation.section);

    }
  }, []);

  function checkIfEvent(value) {
    if (value.category === "special") {
      return <StarIcon className="starIcon" />;
    }
    return "";
  }

  function changeMaxCover(ev) {
    setSelectEventError(false);

    let obj = {};
    let filterDurations = [];
    if (ev.id == 'shift') {
      obj = reservation.section;
      setIsEventSelected(false);
      if (checkDurationPerOfSection(obj)) {
        obj.payment_group.packages.forEach(packageItem => {
          if (packageItem.duration === 0) {
            packageItem.duration = parseInt(obj.settings.block_hour);
          }
          if (!filterDurations.includes(packageItem.duration)) { filterDurations.push(packageItem.duration); }
        });
        if (filterDurations.length) {
          setDurations(filterDurations.sort((a, b) => a > b ? 1 : -1));
          dispatch(updateProperty({ key: "duration", value: filterDurations[0] }));
          // handleWidgetIntervalCapacity(reservation.date, reservation.section.shift_id, reservation.tableFor, 0, 0, reservation.duration ? reservation.duration : filterDurations[0]);
        }
      } else {
        dispatch(updateProperty({ key: "duration", value: '' }));
      }
    }
    else {

      obj = JSON.parse(JSON.stringify(ev));
      if (checkDurationPerOfSection(obj)) {
        obj.payment_group.packages.forEach(packageItem => {
          if (packageItem.duration === 0) {
            packageItem.duration = parseInt(obj.settings.block_hour);
          }
          if (!filterDurations.includes(packageItem.duration)) { filterDurations.push(packageItem.duration); }
        });
        if (filterDurations.length) {
          setDurations(filterDurations.sort((a, b) => a > b ? 1 : -1));
          dispatch(updateProperty({ key: "duration", value: filterDurations[0] }));
          // handleWidgetIntervalCapacity(reservation.date, reservation.section.shift_id, reservation.tableFor, 0, 0, reservation.duration ? reservation.duration : filterDurations[0]);
        }
      } else {
        dispatch(updateProperty({ key: "duration", value: '' }));
      }

      setIsEventSelected(true);
    }

    handleWidgetIntervalCapacity(reservation.date, reservation.section.shift_id, 2, reservation.section_id && ev.id == 'shift' ? reservation.section_id : 0, ev.id == 'shift' ? '' : ev.id, filterDurations.length ? filterDurations[0] : 0);

    let result = obj.max_guest_count < reservation.section.selectedTime.capacity ? obj.max_guest_count : reservation.section.selectedTime.capacity;
    setSelectedMaxCovers(result);
  }

  function validated_guest_count() {
    return (
      (reservation.section.allow_more_than_max_covers &&
        (!selectedEvent.event_id) &&
        reservation.tableFor >= reservation.section.min_guest_count) ||
      (!reservation.section.allow_more_than_max_covers &&
        !selectedEvent.shift_id &&
        reservation.tableFor <= reservation.section.max_guest_count &&
        reservation.tableFor >= reservation.section.min_guest_count) ||
      ((selectedEvent.shift_id || selectedEvent.shift_exception_id) &&
        reservation.tableFor >=
        parseInt(selectedEvent.min_guest_count) &&
        reservation.tableFor <=
        parseInt(selectedEvent.max_guest_count))
    );
  }

  function getSpecialShift(shiftId) {
    dispatch(incrementLoader());
    let query = new URLSearchParams(window.location.search);
    let oid = query.get("oid");
    let obj = {
      outlet_id: oid,
      shift_id: shiftId,
    };

    getShiftById(obj).then((shiftIdResponse) => {
      if (shiftIdResponse.length && shiftIdResponse[0].allowed_days.length) {
        let obj = {
          outlet_id: oid,
          list_type: "interval",
          date_from: shiftIdResponse[0].allowed_days[0],
          date_to:
            shiftIdResponse[0].allowed_days[
            shiftIdResponse[0].allowed_days.length - 1
            ],
        };
        getShifts(obj).then((shifts) => {
          let result = shifts.filter((shift) => {
            return shift.shift_id === shiftId;
          });
          if (result.length) {
            let selectedShift = result[0];
            let availableTime = selectedShift.intervals.filter((interval) => {
              return interval.date === selectedShift.allowed_days[0];
            });

            if (availableTime.length > 0) {
              selectedShift.available_time =
                availableTime[0].online_active_intervals;
            }

            dispatch(
              updateProperty({ key: "selectedEvent", value: selectedShift })
            );
            dispatch(updateProperty({ key: "section", value: selectedShift }));
          }
          dispatch(decrementLoader());
        });
      }
    });

  }

  function next() {
    if (props.mode && props.mode !== 'event' && reservation.preferredTable == '' && reservation.section.allow_guests_to_select_section && preferredTables.length > 0 && !section.allow_more_than_max_covers) {
      return false;
    };
    if (reservation.section.guests_must_book_for_an_event_only && reservation.section.events && reservation.section.events.length && !selectedEvent.id) {
      setSelectEventError(true);
      return false;
    }
    setNextButtonClicked(true);
    if (
      reservation.tableFor &&
      reservation.date &&
      (reservation.section.shift_name || selectedEvent.event_name) &&
      // check if time.start_time
      (reservation.time.time || reservation.selectedEvent.shift_id) &&
      validated_guest_count()) {
      let query = new URLSearchParams(history.location.search);
      let oid = query.get("oid");
      let time = "";
      if (reservation.selectedEvent.shift_id) {
        time = reservation.selectedEvent.available_time[0].start_time;
      } else {
        time = reservation.time.time;
      }
      //let offset = moment.tz.zone(venueDetails.timezone).utcOffset(moment(reservation.date));
      let offset = moment.tz
        .zone(venueDetails.timezone)
        .utcOffset(moment(reservation.date + "T" + time));
      let is_dst = moment.tz(reservation.date, venueDetails.timezone).isDST();
      // if (is_dst) {
      //   offset += 60;
      // }

      let dateTime = moment(reservation.date + "T" + time)
        .add(-offset, "minutes")
        .format("YYYY-MM-DD HH:mm:ss");
      dateTime = moment(reservation.date + "T" + moment(dateTime).format('HH:mm:ss')).format('YYYY-MM-DD HH:mm:ss');
      if ('00:00' <= moment(dateTime).format('HH:mm') && moment(dateTime).format('HH:mm') < '06:00') {
        dateTime = moment(dateTime).add('1', 'd').format('YYYY-MM-DD HH:mm:ss')
      }
      let checkCapacityObj = {
        outlet_id: oid,
        reservation_datetime: dateTime,
        guest_count: reservation.tableFor,
      };
      if (selectedEvent.event_id) {
        checkCapacityObj.event_id = selectedEvent.event_id;
      }
      // if (reservation.selectedEvent.shift_id) {
      //   getSpecialShift(reservation.selectedEvent.shift_id);
      //   history.push({
      //     pathname: "/event/" + reservation.selectedEvent.shift_id,
      //     search: window.location.search,
      //   });
      // } else {
      dispatch(incrementLoader());
      let gtmEvent;
      if (theme.googleTagsManager && theme.googleTagsManagerId) {
        gtmEvent = {
          'event': 'servme_success_capacity_check',
          'reservation_data': 'For ' + checkCapacityObj.guest_count + ' people on ' + moment(checkCapacityObj.reservation_datetime).format('YYYY-MM-DD') + ' at ' + moment(checkCapacityObj.reservation_datetime).format('HH:mm'),
          'reservation_booking_datetime': moment().format('YYYY-MM-DD HH:mm'),
          'reservation_datetime': moment(checkCapacityObj.reservation_datetime).format('YYYY-MM-DD HH:mm'),
          'reservation_guest_count': checkCapacityObj.guest_count,
          'reservation_timeslot': moment(checkCapacityObj.reservation_datetime).format('HH:mm'),
          'reservation_outlet': venueDetails.outlet_name,
          'language': defaultLang
        }
        if (queryParams.get('utm_source')) {
          gtmEvent.utm_source = queryParams.get('utm_source');
        }
        if (queryParams.get('utm_medium')) {
          gtmEvent.utm_medium = queryParams.get('utm_medium');
        }
        if (queryParams.get('utm_campaign')) {
          gtmEvent.utm_campaign = queryParams.get('utm_campaign');
        }
        if (queryParams.get('utm_content')) {
          gtmEvent.utm_content = queryParams.get('utm_content');
        }
        if (queryParams.get('Creative')) {
          gtmEvent.Creative = queryParams.get('Creative');
        }
        if (queryParams.get('Adset')) {
          gtmEvent.Adset = queryParams.get('Adset');
        }
        if (queryParams.get('_gl')) {
          gtmEvent._gl = queryParams.get('_gl');
        }
      }

      if (reservation.duration && reservation.duration !== '') {
        checkCapacityObj.block_hour_duration = reservation.duration;
      }

      if (reservation?.section?.allow_guests_to_select_section) {
        checkCapacityObj.section_id = reservation.section_id;
      } else {
        checkCapacityObj.section_id = 0;
      }

      checkCapacity(checkCapacityObj).then((capacityResponse) => {
        dispatch(decrementLoader());
        if (capacityResponse.status === "success") {
          setCapacityErrors("");
          if (theme.googleTagsManager && theme.googleTagsManagerId) {
            TagManager.dataLayer({
              dataLayer: gtmEvent
            })
          }
          let objectToCheck = reservation.section;
          if (selectedEvent.event_id) {
            objectToCheck = selectedEvent;
          }
          if (
            reservation.tableFor < objectToCheck.min_guest_count ||
            reservation.tableFor > objectToCheck.max_guest_count
          ) {
            // if (has_payment_activated() || has_packages_activated()) {
            if (has_packages_activated()) {
              history.push({
                pathname: "/payment-package",
                search: window.location.search
              });
            } else {
              history.push({
                pathname: "/login",
                search: window.location.search,
                type: "join_waitlist",
              });
            }
          } else {
            // if (has_payment_activated() || has_packages_activated()) {
            if (has_packages_activated()) {
              history.push({
                pathname: "/payment-package",
                search: window.location.search
              });
            } else {
              history.push({
                pathname: "/login",
                search: window.location.search
              });
            }

          };
        } else {
          if (theme.googleTagsManager && theme.googleTagsManagerId) {
            gtmEvent.event = 'servme_failed_capacity_check';
            TagManager.dataLayer({
              dataLayer: gtmEvent
            })
          }

          if (capacityResponse?.error_code === 8154 || capacityResponse?.error_code === 8155) {
            setCapacityErrors(t('no_seats_available'));
          } else {
            setCapacityErrors(capacityResponse.error_description);
          }

          window.scrollTo(0, 0);
        }
      });

      // }
    } else {
      window.scrollTo(0, 0);
    }
  }

  function onChangeAvailableTime(type) {
    alert(type);
    if (type === "event") {
      dispatch(
        updateProperty({
          key: "time",
          value: {
            time: "",
          },
        })
      );
    } else {
      dispatch(
        updateProperty({
          key: "selectedEvent",
          value: {
            section_name: "",
          },
        })
      );
    }
  }
  function iWannaHave() {
    if (reservation.date) {
      return (
        <LayoutContainer spacing={1}>
          <LayoutItem xs={12}>
            <Typography
              variant="h5"
              display="block"
              align="center"
              className="sectionTitle"
            >
              <div style={colorDesign}>
                {theme.iWantToHave && defaultLang === 'en'
                  ? theme.iWantToHave
                  : t("i_want_to_have") + "..."}
              </div>
            </Typography>
          </LayoutItem>
          {/* <LayoutItem xs={12}>
            <Carousel
              slideIndex={selectedCarouselIndex}
              slidesToShow={width < 600 && width > 500 ? 5 : width <= 500 ? 4 : 7}
              renderBottomCenterControls={() => {
                return false;
              }}
              cellSpacing={10}
              renderCenterLeftControls={({ previousSlide }) => (
                <ChevronLeftIcon
                  className="carouselRenderButtons"
                  onClick={previousSlide}
                />
              )}
              renderCenterRightControls={({ nextSlide }) => (
                <ChevronRightIcon
                  className="carouselRenderButtons"
                  onClick={nextSlide}
                />
              )}
            >
              {times_array.flat().map((value, index) => {
                return (
                  <LayoutItem key={index} md={3} sm={6} xs={12} align="center">
                    <Button
                      variant="contained"
                      onClick={(e) => selectSection(value)}
                      fullWidth
                      disabled={value.disabled}
                      style={
                        reservation.section.shift_metadata_name ===
                          value.shift_metadata_name
                          ? selectedStyle
                          : defaultBoxColor
                      }
                      className={
                        reservation.section.shift_metadata_name ===
                          value.shift_metadata_name
                          ? "selected iWannaHaveButton"
                          : "iWannaHaveButton"
                      }
                    >
                      {value}
                      {checkIfEvent(value)}
                    </Button>
                  </LayoutItem>
                )
              })}
            </Carousel>
          </LayoutItem> */}
          <LayoutItem xs={12} className="datepickercont">
            <AvailableTime
              nextButtonClick={nextButtonClicked}
              timeErrorMessage={timeErrorMessage || reservation.section.disabled ? reservation.allShiftsDisabled ? false : true : false}
              sections={sections}
              selectSection={selectSection}
              props={props}
            /><SmallLoader toggle={availableTimeLoader} />
            {/* <AvailableTime
                nextButtonClick={nextButtonClicked}
                timeErrorMessage={timeErrorMessage || reservation.section.disabled ? reservation.allShiftsDisabled ? false : true : false}
              /> */}
          </LayoutItem>
          {/* {sections.map((value, index) => {
            return (
              <LayoutItem key={index} md={3} sm={6} xs={12} align="center">
                <Button
                  variant="contained"
                  onClick={(e) => selectSection(value)}
                  fullWidth
                  disabled={value.disabled}
                  style={
                    reservation.section.shift_metadata_name ===
                      value.shift_metadata_name
                      ? selectedStyle
                      : defaultBoxColor
                  }
                  className={
                    reservation.section.shift_metadata_name ===
                      value.shift_metadata_name
                      ? "selected iWannaHaveButton"
                      : "iWannaHaveButton"
                  }
                >
                  {value.shift_metadata_name}
                  {checkIfEvent(value)}
                </Button>
              </LayoutItem>
            );
          })} */}
          {(!reservation.section.shift_name && reservation.section.shift_name != undefined) && nextButtonClicked &&
            <LayoutItem xs={12}>
              <span className="errorMessage">
                {!reservation.section.shift_name && nextButtonClicked
                  ? t("required")
                  : ""}
              </span>
            </LayoutItem>
          }
          {allShiftsDisabled || reservation.allShiftsDisabled &&
            <LayoutItem xs={12}>
              <span className="errorMessage">
                {allShiftsDisabled || reservation.allShiftsDisabled
                  ? t("we_have_reached_maximum_capacity_at_this_time_please_select_a_different_date")
                  : ""}
              </span>
            </LayoutItem>
          }
        </LayoutContainer>
      );
    }
  }

  function availableTime() {
    if (
      reservation.section.shift_name &&
      (reservation.tableFor <= reservation.section.max_guest_count || theme.showHideTimeslots === 0)
    ) {
      return (
        <AvailableTime
          nextButtonClick={nextButtonClicked}
          timeErrorMessage={timeErrorMessage || reservation.section.disabled ? reservation.allShiftsDisabled ? false : true : false}
        />
      );
    }
  }

  function datepickerAllowedDays() {
    let days = [];
    allowedDays.forEach((value) => {
      days.push(new Date(value));
    });
    return days;
  }

  function fullOrCompactCalendar() {
    if (parseInt(theme.calendarView)) {
      return (
        <LayoutContainer>
          <LayoutItem xs={12} align="center" className={classes.dateCont}>
            <DatePicker
              onChange={onChangeDate}
              inline
              locale={defaultLang}
              className={classes.dateCont}
              selected={
                reservation.date ? new Date(reservation.date) : new Date()
              }
              includeDates={datepickerAllowedDays()}
            />
            <span className="errorMessage">
              {!reservation.date && nextButtonClicked ? t("required") : ""}
            </span>
          </LayoutItem>
        </LayoutContainer>
      );
    } else {
      return (
        <LayoutContainer spacing={1}>
          <LayoutItem xs={12}>
            <Typography
              variant="h5"
              display="block"
              align="center"
              className="sectionTitle SelectedMonth"
            >
              {/* <div style={colorDesign}>
                {defaultLang !== 'de' ? t("on") : ''}{" "}
                {moment(reservation.date).format("MMMM") +
                  " " +
                  moment(allowedDays[0]).format("Y")}
              </div> */}
              <div style={colorDesign}>
                {t('select_a_date')}
              </div>
            </Typography>
          </LayoutItem>
          <LayoutItem xs={12} align="center" className="datepickercont">
            <CompactCarouselDate
              nextButtonClick={nextButtonClicked}
              data={allowedDays}
              onChooseDate={onChangeDate}
            />
            <span className="errorMessage">
              {!reservation.date && nextButtonClicked ? t("required") : ""}
            </span>
          </LayoutItem>
        </LayoutContainer>
      );
    }
  }

  function has_payment_activated() {
    let object_to_check = selectedEvent.event_id ? selectedEvent : reservation.section;
    if (object_to_check.payment_group && object_to_check.payment_group.is_active && object_to_check.payment_group.is_online_active) {
      dispatch(
        updateStateVariable({
          key: "selectedPaymentGroup",
          value: object_to_check.payment_group,
        })
      );
      return true;
    }
    return false
  }

  function has_packages_activated() {
    let object_to_check = selectedEvent.event_id ? selectedEvent : reservation.section;

    if (
      (object_to_check.payment_group) &&
      object_to_check.payment_group.packages &&
      object_to_check.payment_group.packages.length &&
      object_to_check.payment_group.hide_online_packages_categories == false &&
      object_to_check.payment_group.payment_type !== 'preauth' &&
      (
        !object_to_check.payment_group.activate_payment_for_min_cover ||
        (
          object_to_check.payment_group.activate_payment_for_min_cover &&
          object_to_check.payment_group.min_cover_for_payment <= reservation.tableFor
        )
      )
    ) {
      dispatch(
        updateStateVariable({
          key: "selectedPaymentGroup",
          value: object_to_check.payment_group,
        })
      );
      return true;
    }
    return false;
  }

  const handleWidgetIntervalCapacity = (reservationDate, shiftId, guestCount, sectionId, eventId = '', duration = 0, startTime = '') => {
    if (reservationDate && shiftId && guestCount && sectionId !== '') {
      setGuestCountLoader(true);
      setAvailableTimeLoader(true);
      setAvailableEventsLoader(true);
      let oid = query.get("oid");

      let obj = {
        outlet_id: oid,
        reservation_date: reservationDate,
        section_id: sectionId,
        shift_id: shiftId
      };
      if (eventId && eventId !== '') {
        obj.event_id = eventId;
      }

      if (duration && duration !== '') {
        obj.block_hour_duration = duration;
      }

      checkWidgetIntervalCapacity(obj).then((response) => {
        setGuestCountLoader(false);
        setAvailableTimeLoader(false);
        setAvailableEventsLoader(false);
        if (response.status === "success") {
          setCapacityErrors('');
          if (response.result.online_active_intervals && response.result.online_active_intervals.length > 0 && response.result.online_active_intervals.every(item => item.capacity === 0)) {
            setCapacityErrors('There are no seats available for your party size. Please call the restaurant for availability or select another day');
          }
          let eventIntervals = [];
          if (response.result.online_active_intervals && response.result.online_active_intervals.length) {
            eventIntervals = JSON.parse(JSON.stringify(response.result.online_active_intervals));
            //adding shift id to available time
            if (eventId && eventId !== '' && !reservation.section.event_id) {
              let filteredEvent = reservation.section.events.find(item => {
                return item.event_id == eventId;
              })
              eventIntervals.forEach((item, index) => {
                eventIntervals[index].event_id = eventId;
                eventIntervals[index].shift_max_cover = filteredEvent.max_guest_count;
              })
            } else if (eventId && eventId !== '') {

              eventIntervals.forEach((item, index) => {
                eventIntervals[index].event_id = eventId;
                eventIntervals[index].shift_max_cover = selectedEvent.max_guest_count;
              })
            } else {
              let filteredShift = sections.find(item => {
                return item.shift_id == shiftId;
              })
              let shiftOldIntervals = JSON.parse(JSON.stringify(shiftsAvailableTimes));
              eventIntervals.forEach((item, index) => {
                let indexOfIntervals = shiftOldIntervals.findIndex(oldItem => {
                  return item.start_time == oldItem.start_time;
                })
                shiftOldIntervals[indexOfIntervals].capacity = item.capacity;
                shiftOldIntervals[indexOfIntervals].shift_id = shiftId;
                shiftOldIntervals[indexOfIntervals].shift_max_cover = filteredShift.max_guest_count;
                shiftOldIntervals[indexOfIntervals].allow_waitlist = filteredShift.allow_waitlist;
              })
              eventIntervals = shiftOldIntervals;
            }
          }
          let selectedTime = startTime ? startTime : reservation.time.time;
          if (selectedTime) {
            let res = eventIntervals.find(item => {
              return item.start_time == selectedTime
            })
            if (res && res.capacity < selectedMaxCovers)
              setSelectedMaxCovers(res.capacity);
            if (res.capacity < guestCount) {
              setCapacityErrors('There are no seats available for your party size. Please call the restaurant for availability or select another day');
              setHideNextButton(true);
            } else {
              setHideNextButton(false);
            }
          }
          dispatch(
            updateStateVariable({
              key: "allShiftsAvailableTimes",
              value: eventIntervals,
            })
          );
        }
      });
    } else {
      dispatch(
        updateStateVariable({
          key: "allShiftsAvailableTimes",
          value: shiftsAvailableTimes,
        })
      );
    }
  }

  function showSections() {
    if (reservation.section.allow_guests_to_select_section && preferredTables.filter((section) => !section.is_blocked).length > 0) {
      return (
        <LayoutContainer>
          <LayoutItem xs={12}>
            <Divider />
          </LayoutItem>
          <LayoutItem xs={12} align="center">
            <Section handleWidgetIntervalCapacity={handleWidgetIntervalCapacity} />
          </LayoutItem>
        </LayoutContainer>
      )
    }
    return ('')
  }

  const isVenueClosed = () => {
    if (props.mode === 'event') {
      return (selectedEvent && selectedEvent.max_guest_count === 0) || (selectedEvent && selectedEvent.online_active_intervals && selectedEvent.online_active_intervals.length == 0);
      // return selectedEvent && selectedEvent.max_guest_count === 0;
    } else {
      return sections.length === 0 && reservation.section.max_guest_count === 0;
    }
  }

  const isShiftDisplay = () => {
    if (props.mode === 'event') {
      return reservation.date && selectedEvent && selectedEvent.online_active_intervals && selectedEvent.online_active_intervals.length;
    } else {
      return reservation.date && sections.length;
    }
  }

  const isGuestDisplay = () => {
    if (props.mode && props.mode === 'event' && reservation.time.time)
      return selectedEvent.event_id && selectedEvent.max_guest_count !== 0;
    else {
      return reservation.section.shift_id && reservation.section.max_guest_count !== 0;
    }
  }

  const isSectionDisplay = () => {
    return reservation.section.shift_id && reservation.section.allow_guests_to_select_section && reservation.section.max_guest_count !== 0;
  }

  const isAvailableTimeDisplay = () => {
    return reservation.section.shift_id && reservation.section.max_guest_count !== 0;
  }

  const isNextButtonDisplay = () => {
    // ask raz if time should be an object to revert it back to reservation.time.start_time 
    // return (reservation.time.start_time || reservation.section.category === 'special') && reservation.section.max_guest_count !== 0;
    if (props.mode && props.mode === 'event')
      return reservation.time.time && selectedEvent.max_guest_count !== 0 && !hideNextButton
    return reservation.time.time && reservation.section.max_guest_count !== 0 && !hideNextButton;
  }

  function eventCarouselBottomPosition() {
    if (parseInt(theme["eventCarouselPosition"]) === 1 && (highlightedEvents.length || previewMode)) {
      return (
        <>
          {!isVenueClosed() && <LayoutItem xs={12}><Divider /></LayoutItem>}
          <LayoutItem xs={12} className="eventCarouselContainer eventCarouselContainerBottom eventPostSlider" style={widgetBackgroundColor}>
            <EventCarousel getDataFunction={props.getDataFunc}
              data={previewMode ? [{ title: 'test name event', date_from: '2021-11-26 00:00:00', date_to: '2021-11-26 00:00:00', shift_banner_image: ['/test.jpg'] }, { title: 'test name event', date_from: '2021-11-26 00:00:00', date_to: '2021-11-26 00:00:00', shift_banner_image: ['/test.jpg'] }, { title: 'test name event', date_from: '2021-11-26 00:00:00', date_to: '2021-11-26 00:00:00', shift_banner_image: ['/test.jpg'] }, { title: 'test name event', date_from: '2021-11-26 00:00:00', date_to: '2021-11-26 00:00:00', shift_banner_image: ['/test.jpg'] }] : highlightedEvents}
            />
          </LayoutItem>
        </>
      );
    }
  }

  const displayServerMessage = (capacityError) => {
    if (capacityError && capacityError == 'Maximum capacity reached. Please call the restaurant for more seats') {
      return t('maximum_capacity_reached');
    } else if (capacityError && capacityError == 'There are no seats available for your party size. Please call the restaurant for availability or select another day') {
      return t('no_seats_available');
    }
    else {
      return capacityError;
    }
  }

  const buildDurations = (section) => {
    let filterDurations = [];
    if (checkDurationPerOfSection(section)) {
      section.payment_group.packages.forEach((packageItem) => {
        if (!filterDurations.includes(packageItem.duration)) { filterDurations.push(packageItem.duration); }
      });
    }

    if (filterDurations.length) {
      setDurations(filterDurations.sort((a, b) => a > b ? 1 : -1));
      dispatch(updateProperty({ key: "duration", value: reservation.duration ? reservation.duration : filterDurations[0] }));
      handleWidgetIntervalCapacity(reservation.date, reservation.section.shift_id, reservation.tableFor, 0, 0, reservation.duration ? reservation.duration : filterDurations[0]);
    } else {
      dispatch(updateProperty({ key: "duration", value: '' }));
    }
  }

  const showDurations = () => {
    return (
      <Fragment>
        <LayoutItem xs={12}>
          <Divider />
        </LayoutItem>
        <LayoutItem xs={12} className="block-heading">
          <Box component={'div'}>
            <Typography
              variant="h5"
              display="block"
              align="center"
              className="sectionTitle"
            >
              <Box component={'div'} style={colorDesign}>{t("duration")}</Box>
            </Typography>
          </Box>
        </LayoutItem>
        <LayoutItem xs={12} className="datepickercont durationContainer">
          <DurationOfPackage
            durations={durations}
            handleWidgetIntervalCapacity={handleWidgetIntervalCapacity}
          />
          <SmallLoader toggle={guestCountLoader} />
          {!reservation.duration && nextButtonClicked ?
            <LayoutItem xs={12}>
              <span className="errorMessage">
                {!reservation.duration && nextButtonClicked ? t("required") : ""}
              </span>
            </LayoutItem> : <></>
          }
        </LayoutItem>
      </Fragment>
    );
  }

  return (
    <div style={theme}>
      <LayoutContainer
        spacing={1}
        alignItems="center"
        justifyContent="center"
        className={`sectionTable eventNumberGuest`}
      >
        <div className="inner-layout">
          {theme.freeText !== '<p><br></p>' &&
            <>
              {props.mode && props.mode !== 'event' && <LayoutItem xs={12}>
                <div className="tableFor">
                  <div className="eventDetails freeTextHolder datepickercont">
                    <div dangerouslySetInnerHTML={{ __html: theme.freeText }}></div>
                  </div>
                </div>
              </LayoutItem>}
              <LayoutItem xs={12}>
                <Divider />
              </LayoutItem>
            </>
          }

          {capacityErrors &&
            <LayoutItem xs={12} className="capacityErrorMessage">
              <span className="errorMessage">
                {capacityErrors ? displayServerMessage(capacityErrors) : ""}
              </span>
            </LayoutItem>
          }

          <LayoutItem xs={12}>
            <div className="tableFor padding-t-0">
              {fullOrCompactCalendar()}
            </div>
          </LayoutItem>

          {!!isVenueClosed() &&
            <>
              <LayoutItem xs={12}>
                <Divider />
              </LayoutItem>
              <LayoutItem xs={12}>
                <span className="errorMessage smallError">
                  {theme.widgetAddReservationClosedFutureDays && defaultLang === 'en' ? theme.widgetAddReservationClosedFutureDays : t('dear_guest_we_are_not_accepting_online_reservations_at_the_moment_please_call_us_to_make_a_reservation')}
                </span>
              </LayoutItem>
              <LayoutItem xs={12}>
                <Divider />
              </LayoutItem>
            </>
          }

          {/* {!!isAvailableTimeDisplay() &&
            <>
              <LayoutItem xs={12}>
                {availableTime()}
              </LayoutItem>
            </>
          } */}

          {!!isShiftDisplay() &&
            <>
              <LayoutItem xs={12}>
                <Divider />
              </LayoutItem>
              <LayoutItem xs={12}>
                {iWannaHave()}
              </LayoutItem>
            </>
          }
          {reservation.section.events && (reservation.section.events.length > 1 || (reservation.section.events.length && reservation.section.events[0].id !== 'shift')) && props.mode && props.mode !== 'event' ?
            <>
              <LayoutItem xs={12}>
                <Divider />
              </LayoutItem>

              <LayoutContainer spacing={1}>
                <LayoutItem xs={12}>
                  <Typography
                    variant="h5"
                    display="block"
                    align="center"
                    className="sectionTitle availableEventsTitle"
                  >
                    <div style={colorDesign}>
                      {t("available_events_at_this_time")}
                    </div>
                  </Typography>
                </LayoutItem>
              </LayoutContainer>
              {reservation.section.events.map(item => <LayoutItem xs={12} className="eventsListItem">
                <AvailableEvent details={item} onSelect={changeMaxCover} />
              </LayoutItem>
              )}
              <LayoutItem xs={12}>
                <span className="errorMessage">
                  {selectEventError ? t('please_select_an_event_to_proceed') : ''}
                </span>
              </LayoutItem>
            </> : ''
          }
          {!!reservation.time.time && checkDurationPerOfSection(selectedEvent.event_id ? selectedEvent : reservation.section) ? showDurations() : ''}

          {!!isGuestDisplay() &&
            <>
              <LayoutItem xs={12}><Divider /></LayoutItem>
              <LayoutItem xs={12} className="block-heading">
                <div>
                  <Typography
                    variant="h5"
                    display="block"
                    align="center"
                    className="sectionTitle"
                  >
                    <div style={colorDesign}>
                      {theme.tableFor && defaultLang === 'en' ? theme.tableFor : t("table_for") + "..."}
                    </div>
                  </Typography>
                </div>
              </LayoutItem>

              <LayoutItem xs={12} className="datepickercont">
                <NumbersBoxes
                  onChooseNumber={onChooseNumber}
                  limited={reservation.section.allow_more_than_max_covers}
                  min={previewMode ? 1 :
                    selectedEvent && selectedEvent.time_start
                      ? parseInt(selectedEvent.min_guest_count)
                      : reservation.section.min_guest_count
                  }
                  // max={previewMode?10:selectedMaxCovers}
                  max={previewMode ? 10 : selectedEvent && selectedEvent.time_start ? parseInt(selectedEvent.max_guest_count) : reservation.section.max_guest_count}
                  className="numbersContainer"
                />
                <SmallLoader toggle={guestCountLoader} />

                {!reservation.tableFor && nextButtonClicked ?
                  <LayoutItem xs={12}>
                    <span className="errorMessage">
                      {!reservation.tableFor && nextButtonClicked ? t("required") : ""}
                    </span>
                  </LayoutItem> : <></>
                }

                {!validated_guest_count() &&
                  nextButtonClicked &&
                  reservation.tableFor ?
                  <LayoutItem xs={12}>
                    <span className="errorMessage">
                      {!validated_guest_count() &&
                        nextButtonClicked &&
                        reservation.tableFor
                        ? t("maximum_allowed_guest_count_exceeded")
                        : ""}
                    </span>
                  </LayoutItem> : <></>
                }
              </LayoutItem>
            </>
          }

          {reservation.section.shift_name && !isEventSelected && <LayoutItem xs={12}>
            {showSections()}
          </LayoutItem>}

          {eventCarouselBottomPosition()}

          {!!isNextButtonDisplay() &&
            <LayoutItem xs={12} className="padding-tb-0 nextButtonCont">
              <Button
                variant="contained"
                className="buttonFix"
                onClick={next}
                // testing event details
                // ask raz if url or store to navigate to view event details
                // onClick={() => history.push({
                //   pathname: "/event-details",
                //   search: window.location.search
                // })}
                style={buttonDesign}
                disabled={previewMode}
                endIcon={defaultLang === 'ar' ? <ArrowBackIosIcon style={{ color: buttonDesign.color }} /> : <ArrowForwardIosIcon style={{ color: buttonDesign.color }} />}
              >
                {t('next')}
              </Button>
            </LayoutItem>
          }

          <LayoutItem xs={12} className="padding-b-0">
            <Copyright />
          </LayoutItem>
        </div>
      </LayoutContainer>
    </div>
  );
}
